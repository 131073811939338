import React from 'react'
import Reservation from '../Allmenus/FirstMenu/Reservation';
import Specialmenuconfirm from '../Allmenus/Menmenu/Specialmenuconfirm';
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/background/gastro2.jpg'

function Panier() {
  return (
    <>
      <Bredcrumb subtitle="Le recap' de la commande" title="Validons votre commande" Img={Img} />
      <Specialmenuconfirm />
      {/* <Reservation /> */}
    </>
  )
}

export default Panier