import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import bgone from '../../assets/images/background/bg-5.png'
import Bgtwo from '../../assets/images/background/bg-6.png'
import five from '../../assets/images/resource/menu-image-5.png'
import six from '../../assets/images/resource/menu-image-6.png'
import seven from '../../assets/images/resource/menu-image-7.png'
import eight from '../../assets/images/resource/menu-image-8.png'
import nine from '../../assets/images/resource/menu-image-9.png'
import ten from '../../assets/images/resource/menu-image-10.png'
import axios from 'axios';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

function Menusection() {
    const [tabMenu, setTabMenu] = useState('a');
    const [plats, setPlats] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://vps98196.serveur-vps.net/api/Plats/menu');
                setPlats(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        };

        fetchData();
    }, []);

    const groupedPlats = plats.reduce((grouped, plat) => {
        if (!grouped[plat.categorieDto.nom]) {
            grouped[plat.categorieDto.nom] = [];
        }
        grouped[plat.categorieDto.nom].push(plat);
        return grouped;
    }, {});

    const platsDeLaCategorie = groupedPlats[tabMenu] || [];  // Plats de la catégorie sélectionnée


    return (
        <>
            <section className="menu-section" id='section-menu'>

                <div className="left-bg"><img src={bgone} alt="" title="" /></div>

                <div className="right-bg"><img src={Bgtwo} alt="" title="" /></div>

                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Des mets de qualité, pour des clients premium</span></div>

                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>
                        <Zoom>
                            <h2>Notre Carte</h2>
                        </Zoom>

                    </div>

                    <div className="tabs-box menu-tabs">
                        <div className="buttons">
                            <ul className="tab-buttons clearfix">
                                {Object.keys(groupedPlats).length === 0 ?
                                    <li>Veuillez patienter s'il vous plaît. La carte charge</li> :

                                    Object.keys(groupedPlats).map((category, index) => (
                                       <Zoom>
                                         <li
                                            key={category}
                                            className={`tab-btn ${tabMenu === category && "active-btn"}`}
                                            onClick={() => setTabMenu(category)}  // Mettre à jour le bon onglet
                                            tabIndex={index + 1}  // Ajouter tabIndex pour la navigation au clavier
                                        >
                                            <span>{category}</span>
                                        </li>
                                       </Zoom>
                                    ))}

                            </ul>
                        </div>
                        <div className="tabs-content">

                            <div className={`tab ${tabMenu && "active-tab"}`} id="tab-1">
                                <div className="row clearfix">
                                    <div className="menu-col col-md-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {platsDeLaCategorie.map((plat, index) => (
                                                <div className="dish-block" key={index}>
                                                    <div className="inner-box">
                                                        {/* <div className="dish-image"><Link to="#"><img src={plat.image} alt="" /></Link></div> */}
                                                        <div className="title clearfix">
                                                            <div className="ttl clearfix"><h5><Link to="#">{plat.nom}</Link></h5></div>
                                                            <div className="price"><span>{plat.prix} XAF</span></div>
                                                        </div>
                                                        <div className="text desc"><Link to="#">{plat.description}</Link></div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                            </div>




                        </div>
                    </div>

                    <div className="open-timing">
                        <div className="hours">Les commandes se font entre <span className="theme_color">12h00 </span>et <span className="theme_color">22h00.</span></div>
                        {/* <div className="link-box">
                            <Link to="#" className="theme-btn btn-style-two clearfix">
                                <span className="btn-wrap">
                                    <span className="text-one">view all menu</span>
                                    <span className="text-two">view all menu</span>
                                </span>
                            </Link>
                        </div> */}
                    </div>
                </div>

            </section>
        </>
    )
}

export default Menusection