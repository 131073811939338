import React, { useState } from 'react';
import Backgroundimage from '../../assets/images/resource/Story1.JPG';
import { Link } from 'react-router-dom'
import logonew from '../../assets/images/resource/blogo.jpg'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

function Main() {

    const [isLoading, setIsLoading] = useState(false); // Ajout de l'état isLoading
    const handleFormSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true); // Activer l'indicateur de chargement au début de la requête

        const formData = new FormData(event.target);

        const dataToSend = {
            email: formData.get('email'),

            // message: formData.get('fieldmessage'),
            // ... Ajoutez d'autres champs si nécessaire
        };
        console.log(dataToSend)
        // Envoi de la requête POST
        fetch('https://vps98196.serveur-vps.net/api/Newsletter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Gérer la réponse ici
                setIsLoading(false); // Désactiver l'indicateur de chargement une fois la réponse obtenue

                alert('Votre souscription a été bien prise en compte !')
                console.log('Réponse du serveur :', data);
            })
            .catch(error => {
                // Gérer les erreurs ici
                setIsLoading(false); // Désactiver l'indicateur de chargement une fois la réponse obtenue
                alert('Une erreur est surevenue ! Merci de réessayer dans quelques instants.')
                console.log('Erreur lors de l\'envoi de la requête :', error);
            });
    };

    return (
        <>
            <footer className="main-footer">
                <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}> </div>
                <div className="upper-section">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <Fade bottom>
                                <div className="footer-col info-col col-lg-6 col-md-12 col-sm-12">
                                    <div className="inner wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        <div className="content">
                                            <div className="logo"><Link to="/" title=" Restaurant Le Bacchus Plus"><img src={logonew} alt="" title="Restaurant Le Bacchus Plus" /></Link></div>
                                            <div className="info">
                                                <ul>
                                                    <li>Restaurant Le Bacchus Plus,<br /> Elig - Essono, derrière Mahima Yaoundé - Cameroun</li>
                                                    <li><Link to="mailto: bbacchus237@gmail.com">bbacchus237@gmail.com</Link></li>
                                                    <li><Link to="tel:+237698598802">(+237) 698-598-802 / 697-106-601</Link></li>
                                                    <li>Lundi à Samedi : 12h00 - 23h00</li>
                                                    <li>Dimanche : sous réservation uniquement</li>
                                                </ul>
                                            </div>
                                            <div className="separator"><span></span><span></span><span></span></div>
                                            
                                            {/* <div className="newsletter">
                                                <h3>Inscrivez-vous à notre newsletter</h3>
                                                
                                                <div className="newsletter-form">
                                                    <form onSubmit={handleFormSubmit}>
                                                        <div className="form-group">
                                                            <span className="alt-icon far fa-envelope"></span>
                                                            <input type="email" name="email" placeholder="Votre email" required />
                                                            <button type="submit" className="theme-btn btn-style-one clearfix" disabled={isLoading}>
                                                                <span className="btn-wrap">
                                                                    <span className="text-one">{isLoading ? 'Patientez...' : 'Souscrire'}</span>
                                                                    <span className="text-two">{isLoading ? 'Patientez...' : 'Souscrire'}</span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div> */}

                                        </div>
                                    </div>
                                </div>
                            </Fade>


                            <div className="footer-col links-col col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <Fade left>
                                            <li> <Link to="/">Acceuil</Link></li>
                                            {/* <li> <Link to="">Évènements</Link></li> */}
                                            <li> <Link to="/about">Restaurant</Link></li>
                                            {/* <li> <Link to="">Carte</Link></li> */}
                                            <li> <Link to="/reservation">Réservation</Link></li>
                                            <li> <Link to="/commande">Commande</Link></li>
                                            {/* <li> <Link to="">Fideelité</Link></li> */}
                                            <li> <Link to="/contact">Contact</Link></li>
                                        </Fade>

                                    </ul>
                                </div>
                            </div>

                            <div className="footer-col links-col last col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <Fade right>
                                            <li> <Link to="https://web.facebook.com/profile.php?id=100090091720468&mibextid=ZbWKwL&_rdc=1&_rdr" target="_blank">facebook</Link></li>
                                            <li> <Link to="https://www.instagram.com/bacchusplus/?igshid=MzRlODBiNWFlZA%3D%3D" target="_blank">instagram</Link></li>
                                            <li> <Link to="https://www.tiktok.com/@user5488266270342?_t=8gyDDj1q75Z&_r=1" target="_blank">TikTok</Link></li>
                                            <li> <Link to="https://www.google.com/maps/place/Le+BACCHUS+PLUS+Yaound%C3%A9/@3.8776385,11.5232408,17z/data=!3m1!4b1!4m6!3m5!1s0x108bc59f829b4cff:0xae166b0bbf72d7a1!8m2!3d3.8776385!4d11.5258211!16s%2Fg%2F11g2xr3fpy?entry=ttu" target="_blank">Google map</Link></li>

                                        </Fade>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="copyright">&copy; 2023 Restaurant Le Bacchus Plus. Tous droits réservés  |  Crafted by <Link to="https://www.instagram.com/the_horizon_content/" target="blank">The Horizon Content</Link>, <a href="mailto:dualabrianderic@gmail.com">Briand Duala</a> & <a href="mailto:nzefetaurelien@gmail.com">Aurélien Nzefet </a>  </div>
                    </div>
                </div>

            </footer>
            <div className="scroll-to-top scroll-to-target" data-target="html"><span className="icon fa fa-angle-up"></span></div>
        </>
    )
}

export default Main