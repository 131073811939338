import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Bgtwo from '../../assets/images/background/image-2.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import author1 from '../../assets/images/resource/memory1.jpg'
import author2 from '../../assets/images/resource/author-thumb-2.jpg'
import author3 from '../../assets/images/resource/author-thumb-3.jpg'
import SwiperCore, { Controller } from 'swiper';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import DatePicker from "react-datepicker";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import "react-datepicker/dist/react-datepicker.css";

SwiperCore.use([Controller]);
function Contact() {

    const swiper1 = useRef(null);
    const swiper2 = useRef(null);

    const [flag, setflag] = useState(false)

    const slider2 = useRef()
    const duration = 500;
    const syncPosition = (e) => {
        if (!flag) {
            setflag(false)
            if (slider2.current) {
                slider2.current.to(e.item.index, duration)
            }
            setflag(false)
        }
    }

    const VideoComponent = styled.video`
min-width:100%;
min-height: 100vh;
position: absolute;
object-fit : cover;
top:0;
`;

    const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
    const [startDate, setStartDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false); // Ajout de l'état isLoading
  

  
    const handleFormSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true); // Activer l'indicateur de chargement au début de la requête

        const formData = new FormData(event.target);

        const dataToSend = {
            name: formData.get('fieldname'),
            telephone: formData.get('fieldnumber'),
            nbrePersonnes: parseInt(formData.get('fieldPerson').split(" ")[0]),
            date: startDate.toISOString(),
            // message: formData.get('fieldmessage'),
            // ... Ajoutez d'autres champs si nécessaire
        };
        console.log(dataToSend)
        // Envoi de la requête POST
        fetch('https://vps98196.serveur-vps.net/api/reservation/reserver', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataToSend),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            // Gérer la réponse ici
            setIsLoading(false); // Désactiver l'indicateur de chargement une fois la réponse obtenue

            alert('La reservation a été effectué avec succès ! Nous vous contacterons sous peu pour davantage d\'informations.')
            console.log('Réponse du serveur :', data);
          })
          .catch(error => {
            // Gérer les erreurs ici
            setIsLoading(false); // Désactiver l'indicateur de chargement une fois la réponse obtenue
            alert('Une erreur est surevenue ! Merci de réessayer dans quelques instants.')
            console.error('Erreur lors de l\'envoi de la requête :', error);
          });
    };

    return (
        <>
            <div className="testimonials-section" >
                <VideoComponent controls autoPlay loop>
                    <source src={require('../../assets/images/resource/Tablebooking.MP4')} type="video/mp4" />Your browser does not support the video tag. I suggest you upgrade your browser.
                </VideoComponent>
                <Overlay />
                <div className="auto-container">
                    <div className="carousel-box owl-theme">
                        <Swiper className="testi-top"
                            ref={swiper1}
                            controller={{ control: swiper2.current }}
                            items={1} loop margin={10} autoplay onChange={(e) => syncPosition(e)}>
                            <div className="slide-item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text"> C'est le moment de créer des souvenirs gourmands ensemble. Réservez votre table aujourd'hui !

                                    </div>
                                </div>
                            </div >
                        </Swiper>
                        <div className="separator"  id='reserve-section'><span></span><span></span><span></span></div>
                        <div className="thumbs-carousel-box">
                            <div className="testi-thumbs" loop={true} autoplay={true}>
                                <SwiperSlide className="slide-item">
                                    <div className="image">
                                        <img style={{ width: 300, height: 100, objectFit: 'cover' }} src={author1} alt="" />
                                    </div>
                                    <div className="auth-title"></div>
                                </SwiperSlide >
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Fade top>
                
                <section className="reserve-section">
                    <div className="auto-container">
                        <div className="outer-box">
                            <form onSubmit={handleFormSubmit}>
                                <div className="row clearfix">
                                    <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                                        <div className="inner">
                                            <div className="title">
                                                <h2>Réserver une table en ligne</h2>
                                                <div className="request-info">Pour plus de 07 personnes, réservez par téléphone au <Link to="#"> (+237) 698-598-802 | 697-106-601</Link></div>
                                            </div>
                                            <div className="default-form reservation-form">

                                                <div className="row clearfix">
                                                    <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                        <div className="field-inner">
                                                            <input type="text" name="fieldname" placeholder="Votre nom" required />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                        <div className="field-inner">
                                                            <input type="text" name="fieldnumber" placeholder="Votre numéro de téléphone" required />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                        <div className="field-inner">
                                                            <span className="alt-icon far fa-user"></span>
                                                            <select className="l-icon" name='fieldPerson'>
                                                                <option>1 Personne</option>
                                                                <option>2 Personnes</option>
                                                                <option>3 Personnes</option>
                                                                <option>4 Personnes</option>
                                                                <option>5 Personnes</option>
                                                                <option>6 Personnes</option>
                                                                <option>7 Personnes</option>
                                                            </select>
                                                            <span className="arrow-icon far fa-angle-down"></span>
                                                        </div>
                                                    </div>
                                                    <DatePicker
                                                        showTimeSelect
                                                        selected={startDate} 
                                                        onChange={(date) => setStartDate(date)}
                                                        customInput={
                                                            <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                                <div className="field-inner">
                                                                    <span className="alt-icon far fa-calendar"></span>
                                                                    <input className="l-icon datepicker" type="text" name="fielddate" placeholder={startDate}  required readOnly />
                                                                    <span className="arrow-icon far fa-angle-down"></span>
                                                                </div>
                                                            </div>
                                                        }
                                                    />

                                                    {/* <div className="form-group col-lg-4 col-md-12 col-sm-12">
                                                        <div className="field-inner">
                                                            <span className="alt-icon far fa-clock"></span>
                                                            <select className="l-icon">
                                                                <option>12 : 00 pm</option>
                                                                <option>01 : 00 pm</option>
                                                                <option>02 : 00 pm</option>
                                                                <option>03 : 00 pm</option>
                                                                <option>04 : 00 pm</option>
                                                                <option>05 : 00 pm</option>
                                                                <option>06 : 00 pm</option>
                                                                <option>07 : 00 pm</option>
                                                                <option>08 : 00 pm</option>
                                                                <option>09 : 00 pm</option>
                                                                <option>10 : 00 pm</option>
                                                            </select>
                                                            <span className="arrow-icon far fa-angle-down"></span>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                        <div className="field-inner">
                                                            <textarea name="fieldmessage" placeholder="Message (Optionnel)" ></textarea>
                                                        </div>
                                                    </div> */}
                                                 
                                                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                        <div className="field-inner">

                                                            <button disable={isLoading} type="submit" className="theme-btn btn-style-one clearfix">
                                                                <span className="btn-wrap">
                                                                    <span className="text-one">{isLoading ? 'Patientez...' : 'Réserver une table'}</span>
                                                                    <span className="text-two">{isLoading ? 'Patientez...' : 'Réserver une table'}</span>
                                                                </span>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="info-col col-lg-4 col-md-12 col-sm-12">
                                        <div className="inner">
                                            <div className="title">
                                                <h2>Nos contacts</h2>
                                            </div>
                                            <div className="data">
                                                <div className="booking-info">
                                                    <div className="bk-title">Une préoccupation ?</div>
                                                    <div className="bk-no"><Link to="tel:+237698598802">698-598-802</Link></div>
                                                </div>
                                                <div className="separator"><span></span></div>
                                                <ul className="info">
                                                    <li><strong>Localisation</strong><br /> Restaurant Le Bacchus Plus, Elig - Essono, derrière Mahima Yaoundé - Cameroun</li>
                                                    <li><strong>Horaires</strong><br />De Lundi à Samedi <br />12h00 - 23h00</li>
                                                    <li>Dimanche<br />Uniquement sous réservation</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </Fade>
        </>
    )
}

export default Contact