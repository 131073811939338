import React from 'react'
import Reservation from '../Allmenus/FirstMenu/Reservation';
import Specialmenu from '../Allmenus/Menmenu/Specialmenu';
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/background/gastro1.jpg'

function Menutwo() {
  return (
    <>
      <Bredcrumb subtitle="Commandez maintenant" title="Vos délices surplace ou à emporter" Img={Img} />
      <Specialmenu />
      {/* <Reservation /> */}
    </>
  )
}

export default Menutwo