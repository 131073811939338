import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Backgroundimage from "../../../assets/images/background/image-8.jpg";
import bg19 from "../../../assets/images/background/bg-19.png";
import bg20 from "../../../assets/images/background/bg-20.png";
import img8 from "../../../assets/images/background/image-8.jpg";
import badgeone from "../../../assets/images/resource/badge-2.png";
import One from '../../../assets/images/resource/menu-image-11.jpg'
import Two from '../../../assets/images/resource/menu-image-12.jpg'
import Three from '../../../assets/images/resource/menu-image-13.jpg'
import Four from '../../../assets/images/resource/menu-image-14.jpg'
import Five from '../../../assets/images/resource/menu-image-15.jpg'
import Six from '../../../assets/images/resource/menu-image-16.jpg'
import Seven from '../../../assets/images/resource/menu-image-17.jpg'
import Eight from '../../../assets/images/resource/menu-image-18.jpg'
import axios from "axios";
import Zoom from 'react-reveal/Zoom';
import styled from "styled-components";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';


const CarteContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  gap: 20px;
  padding: 60px;
  position: relative; /* Assurez-vous que le contenu de CarteContainer est empilé au-dessus de l'overlay */
  z-index: 1; /* Gardez le contenu au-dessus de l'overlay */
`;


const CartePlat = styled.div`
  width: 300px; /* Ajustez la largeur en fonction de votre mise en page */

  border: 1px solid rgb(228,197,144);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
  }

    .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  .add-to-cart-button {
    background-color: rgb(228,197,144);

    border: none;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    outline: none; // Supprime la bordure par défaut
  }

  .quantity-container {
    display: flex;
    align-items: center;
  }

  .quantity-button {
    background-color: rgb(228,197,144);

    border: none;
    padding: 1px 10px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 50%;
    margin: 5px 5px;
    outline: none; // Supprime la bordure par défaut
 
`;

const PlatImage = styled.img`
  width: 100%;
  max-height: 150px;
  object-fit: cover;
  border-radius: 8px;
`;

const PlatNom = styled.h3`
  font-size: 18px;
  margin: 10px 0;
`;

const PlatPrix = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

const PlatDescription = styled.p`
  font-size: 14px;
  color: #666;
`;

const Title = styled.h2`
font-size: 22px;
color:white;
  @media (min-width: 1150px) {
  font-size: 48px;
  margin-bottom: 10px;
z-index:1;
   
   }
`;

const Description = styled.p`
font-size: 15px;

  color: white;
  @media (min-width:1150px) {
	font-size: 18px;
	padding:52px;
	 
	 }

`;

const GreenContainer = styled.div`
@media (min-width: 768px) {
	display: flex;
	justify-content: center;
	align-items: center;
	
	 }
	 background: #047E34;
	 flex-direction:column;
	 height:30vh;
	 position:fixed;
   z-index:2;
   top:0;
   width:100%;
  
`;

const Image = styled.img`
@media (min-width: 768px) {


  width: 50%;

  
  }
object-fit: cover;

 width:100%;
 height:100px;
`;

const MyButton = styled.a`
  background-color: transparent;
  border: 2px solid rgb(228,197,144);
  color: white;
  padding: 10px 20px;
  align-self:center;

  text-decoration: none;
 
  transition: background-color 0.3s, color 0.3s;
border-radius:8px;
  &:hover {
    background-color: white;
    color: black
  }
`;

const MyBadge = styled.span` {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 12px;
  position: absolute;
  top: -8px;
  right: -8px;
}
`;

function Specialmenu() {

  const [plats, setPlats] = useState([]);
  const [cart, setCart] = useState({}); // État pour gérer le panier d'achat
  const addToCart = (plat) => {
    const updatedCart = { ...cart };
    if (updatedCart[plat.id]) {
      updatedCart[plat.id].quantity += 1;
    } else {
      updatedCart[plat.id] = { ...plat, quantity: 1 };
    }
    setCart(updatedCart);

    // Mettez à jour le compteur du panier
    setCartItemCount(cartItemCount + 1);
  };

  // Fonction pour augmenter la quantité d'un plat dans le panier
  const increaseQuantity = (platId) => {
    const updatedCart = { ...cart };
    updatedCart[platId].quantity += 1;
    setCart(updatedCart);
    setCartItemCount(cartItemCount + 1);
  };

  // Fonction pour diminuer la quantité d'un plat dans le panier
  const decreaseQuantity = (platId) => {
    const updatedCart = { ...cart };
    if (updatedCart[platId].quantity > 1) {
      updatedCart[platId].quantity -= 1;
    } else {
      delete updatedCart[platId];
    }
    setCart(updatedCart);
    if (cartItemCount > 0) {
      setCartItemCount(cartItemCount - 1);
    }

  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://vps98196.serveur-vps.net/api/Plats/menu');
        setPlats(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };

    fetchData();
  }, []);

  const groupedPlats = plats.reduce((grouped, plat) => {
    if (!grouped[plat.categorieDto.nom]) {
      grouped[plat.categorieDto.nom] = [];
    }
    grouped[plat.categorieDto.nom].push(plat);
    return grouped;
  }, {});

  const [cartItemCount, setCartItemCount] = useState(0);

  const navigate = useNavigate();

  return (
    <>
      <div style={{

        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 10,
      }}>
        {/* <MyButton style={{ marginRight: '20px' }} href="/mesCommandes" rel="noopener noreferrer">Voir mes commandes</MyButton> */}

        <MyButton onClick={() => { navigate('/panier', { state: { cart } }) }} rel="noopener noreferrer">
          <FontAwesomeIcon style={{}} icon={faShoppingCart} />{' '}
          {cartItemCount > 0 && <MyBadge>{cartItemCount} élément(s) au panier</MyBadge>}

        </MyButton>

      </div>
      <section className="special-offer-two">
        <div className="left-bg">
          <img src={bg20} alt="" title="" />
        </div>
        <div className="right-bg">
          <img src={bg19} alt="" title="" />
        </div>
        <div className="auto-container">
          <div className="title-box centered">
            {/* <div className="subtitle">
              <span>special offer</span>
            </div> */}
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/separator.svg").default} alt="" title="" />
            </div>

            {Object.keys(groupedPlats).length === 0 ?
              <h3>Veuillez patienter s'il vous plaît. La carte charge.</h3>
              :
              <></>
            }
            {Object.keys(groupedPlats).map((category, index) => (
              <div style={{ paddingTop: 10 }} key={index}>
                <h2 style={{ color: 'white', textDecoration: 'underline' }}>
                  {category}
                </h2>
                <CarteContainer>
                  {groupedPlats[category].map((plat, platIndex) => (
                    <CartePlat key={platIndex}>
                      {/* <PlatImage src={plat.image} alt={plat.nom} /> */}
                      <PlatNom>{plat.nom}</PlatNom>
                      <br />
                      <PlatPrix>{plat.prix} XAF</PlatPrix>
                      <PlatDescription>{plat.description}</PlatDescription>

                      {/* Boutons pour ajouter au panier et gérer la quantité */}
                      <div className="button-container">
                        <button
                          className="add-to-cart-button"
                          onClick={() => addToCart(plat)}
                        >
                          Ajouter au panier
                        </button>
                        {cart[plat.id] && (
                          <div className="quantity-container">
                            <button
                              className="quantity-button"
                              onClick={() => decreaseQuantity(plat.id)}
                            >
                              -
                            </button>
                            <span>{cart[plat.id].quantity}</span>
                            <button
                              className="quantity-button"
                              onClick={() => increaseQuantity(plat.id)}
                            >
                              +
                            </button>
                          </div>
                        )}
                      </div>
                    </CartePlat>
                  ))}
                </CarteContainer>
              </div>
            ))}



            {/* {Object.keys(groupedPlats).map((category, index) => (
              <div key={index}>
                <h2>
                  {category}
                </h2>
                <div style={{
                  flexDirection:'column',
                  display:'flex'
                }}>
                  <div >
                    {groupedPlats[category].map((plat, platIndex) => (

                      <><div key={platIndex} className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                        <div className="image">
                          <img src={plat.image} alt={plat.nom} />
                        </div>
                        <h4>
                          {plat.nom}
                        </h4>
                        <div className="text desc">
                          {plat.description}
                        </div>
                        <div className="price">{plat.prix.toFixed(2)} XAF</div>
                      </div><div className="button-container">
                          <button
                            className="add-to-cart-button"
                            onClick={() => addToCart(plat)}
                          >
                            Ajouter au panier
                          </button>
                          {cart[plat.id] && (
                            <div className="quantity-container">
                              <button
                                className="quantity-button"
                                onClick={() => decreaseQuantity(plat.id)}
                              >
                                -
                              </button>
                              <span>{cart[plat.id].quantity}</span>
                              <button
                                className="quantity-button"
                                onClick={() => increaseQuantity(plat.id)}
                              >
                                +
                              </button>
                            </div>
                          )}
                        </div></>

                    ))}
                  </div>


                </div>
              </div>
            ))} */}







          </div>

        </div>
      </section>

      {/* <section className="chef-selection">
          <div className="auto-container">
            <div className="outer-container">
              <div className="row clearfix">
                <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="inner wow fadeInLeft"
                    data-wow-duration="1500ms" data-wow-delay="0ms">
                    <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}></div>
                    <div className="image">
                      <img src={img8} alt="" />
                    </div>
                  </div>
                </div>

                <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                    <div className="title-box">
                      <span className="badge-icon">
                        <img src={badgeone} alt="" title="" />
                      </span>
                      <div className="subtitle">
                        <span>chef selection</span>
                      </div>

                      <div className="pattern-image">
                        <img src={require("../../../assets/images/icons/separator.svg").default} alt="mySvgImage" />
                      </div>

                      <h3>Lobster Tortellini</h3>
                      <div className="text">
                        Lorem Ipsum is simply dummy text of the printingand
                        typesetting industry lorem Ipsum has been the industrys
                        standard dummy text ever since.
                      </div>
                    </div>
                    <div className="price">
                      <span className="old">$40.00</span>{" "}
                      <span className="new">$20.00</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section> */}
    </>
  );
}

export default Specialmenu;
