import React from 'react'
import Banner from '../Menhome/Banner'
import Offer from '../Menhome/Offer'
import Story from '../Menhome/Story'
import Specialdish from '../Menhome/Specialdish'
import Menusection from '../Menhome/Menusection'
import Whyus from '../Menhome/Whyus'
import Teem from '../Menhome/Teem'
import Reacntupdate from '../Menhome/Recntupdates'
import Special from '../Menhome/Special'
import Contact from '../Menhome/Contact'
import Videosection from '../Menhome/Videosection'
import { Link } from 'react-router-dom'
import sidebarlogo from '../../assets/images/resource/sidebar-logo.png'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Winningchef from '../Menabout/Winningchef'

function Homethree() {
    return (
        <>
            <div className="menu-backdrop"> </div>

            <section className="hidden-bar">
                <div className="inner-box">
                    <div className="cross-icon hidden-bar-closer"><span className="far fa-close"></span></div>
                    <div className="logo-box"><Link to="/" title="Le Bacchus Plus - Restaurants Gastronomique"><img src={sidebarlogo} alt="" title="Le Bacchus Plus - Restaurants Gastronomique" /></Link></div>


                    <div className="side-menu">
                        <ul className="navigation clearfix">
                            <li className="current dropdown"><Link to="/">Home</Link>
                            </li>
                            <li className="dropdown"><Link to="/menu">Menus</Link>
                                <ul>
                                    <li><Link to="/menuone">Menu List 1</Link></li>
                                    <li><Link to="/commande">Menu List 2</Link></li>
                                    <li><Link to="/menuthree">Menu List 3</Link></li>
                                    <li><Link to="/reservation">Menu List 4</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/ourcheaf">Our chefs</Link></li>
                            <li className="dropdown"><Link to="#">Pages</Link>
                                <ul>
                                    <li><Link to="#">Dropdown Menu 1</Link></li>
                                    <li><Link to="#">Dropdown Menu 2</Link></li>
                                    <li><Link to="#">Dropdown Menu 3</Link></li>
                                    <li className="dropdown"><Link to="#">Dropdown Menu 4</Link>
                                        <ul>
                                            <li><Link to="#">Dropdown Menu level 2</Link></li>
                                            <li><Link to="#">Dropdown Menu level 2</Link></li>
                                            <li><Link to="#">Dropdown Menu Level 3</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/">Dropdown Lorem 5</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>


                    <ul className="info">
                        <li>Restaurant Le Bacchus Plus, Elig <br /> Essono, derriere Mahima Yaoundé - Cameroun </li>
                        <li>Open: 9.30 am - 2.30pm</li>
                        <li><Link to="mailto:booking@domainame.com">booking@domainame.com</Link></li>
                    </ul>
                    <div className="separator"><span></span></div>
                    <div className="booking-info">
                        <div className="bk-title">Booking request</div>
                        <div className="bk-no"><Link to="tel:(+237) 698-598-802 / 697-106-601">(+237) 698-598-802 / 697-106-601</Link></div>
                    </div>

                </div>
            </section>

            <Banner />
            <Fade bottom>
                <Zoom>
                    <Offer />
                </Zoom>

            </Fade>
            {/* <Winningchef/> */}

            <Fade bottom>
                <Zoom>
                    <Menusection id='section-menu' />
                </Zoom>

            </Fade>



            <Contact id='book-table'/>
            <Story />

            <Whyus />

            {/* <Reacntupdate /> */}
        </>
    )
}

export default Homethree