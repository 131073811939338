import React from 'react'
import Reservation from '../Allmenus/FirstMenu/Reservation'
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/background/ourspace.JPG'
import Menusection from '../Menhome/Menusection'
import Contact from '../Menhome/Contact'


function Menufour() {
  return (

    <>
      {/* <Bredcrumb subtitle="Réservez une table" title="Votre restaurant gastronomique" Img={Img} /> */}
      {/* <Menusection/> */}
      <Contact/>

    </>

  )
}

export default Menufour