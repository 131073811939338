import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AnimatedNumber from "animated-number-react"
import Iframe from 'react-iframe';
import bgten from '../../assets/images/background/bg-10.png'
import bgeleven from '../../assets/images/background/bg-11.png'
import signatureone from '../../assets/images/resource/signature-1.png'
import bgimg from '../../assets/images/resource/Story1.JPG';
import bgimg2 from '../../assets/images/resource/Story2.JPG';
import aboutimgone from '../../assets/images/resource/cocktails1.png'
import aboutimgtwo from '../../assets/images/resource/Intro.mp4'

function Aboutsec() {

    const [Video, setVideo] = useState();

    return (
        <>
            <section className="about-section">
                <div className="left-bg"><img src={bgten} alt="" title="" /></div>
                <div className="right-bg"><img src={bgeleven} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Un peu d'histoire</span></div>

                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h4>Havre de paix en plein centre de Yaoundé, le restaurant Le BacchusPlus offre une variété de plats raffinés inspirés des gastronomies du monde. Le Bacchus Plus anciennement <span className="theme_color">Bacchus Restaurant Suisse </span> a élargi sa carte en y ajoutant de nombreux plats et nous emmène à la découverte d'un nouveau type de cuisine : la cuisine de fusion. Les nouveaux propriétaires, Belgo-Camerounais, ont voulu créer une atmosphère récréative en proposant des espaces intimistes invitant à la quiétude et à la discrétion. Le restaurant est ouvert du lundi au samedi de 12h à 23h, fermé le dimanche (sauf pour des réservations d'un minimum de 10 personnes).</h4>
                    </div>
                    <div className="row clearfix">

                        <div className="about-block content-block col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="text" style={{}}>"Le Bacchus Plus, temple de l'art gastronomique, incarne l'apogée du plaisir culinaire. Chaque plat est une composition magistrale, où les saveurs s'entrelacent dans une danse exquise, éveillant les papilles à des expériences sensorielles inoubliables."</div>

                                <div className="author">Mme Edith ABESSOLO - Gérante</div>
                                <div className="video-box">
                                    <div className="image"><Link to={aboutimgtwo} className="lightbox-image"><img src={bgimg} alt="" /></Link>
                                        <Link to="#" className="lightbox-image play-btn" onClick={() => setVideo(true)}>
                                            <span className="icon fal fa-play">
                                                <i className="ripple"></i>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="about-block image-block col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                <div className="image"><img src={aboutimgone} alt="" /></div>
                            </div>
                        </div>


                        <div className="about-block info-block col-xl-4 col-lg-4 col-md-12 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                <ul className="info">
                                    <li><strong>Nos horaires</strong>De Lundi à Samedi <br />12:00 - 23:00</li>
                                    <li>Dimanche <br />Uniquement sous réservation</li>
                                    <li className="separator"><span></span></li>
                                    <li><strong>Nos Contacts</strong><span className="address">Restaurant Le Bacchus Plus, Elig - Essono, derrière Mahima Yaoundé - Cameroun</span> <Link to="tel:+237698598802">(+237) 698-598-802</Link>  <br /> <Link to="tel:+237697106601">(+237) 697-106-601</Link> <br /> <Link to="mailto:bbacchus237@gmail.com">bbacchus237@gmail.com</Link></li>
                                </ul>
                            </div>
                        </div>

                    </div>
             
                </div>

                {Video &&
                    <div className={`fancybox-container fancybox-is-open fancybox-can-drag fancybox-can-drag ${Video && "fancybox-is-closing"} fancybox-show-toolbar`} onClick={() => { setVideo(false) }} role="dialog" tabindex="-1" id="fancybox-container-9" style={{ transitionDuration: "500ms" }}>
                        <div className="fancybox-bg"></div>
                        <div className="fancybox-inner">
                            <div className="fancybox-infobar">
                                <span data-fancybox-index="">1</span>&nbsp;/&nbsp;
                                <span data-fancybox-count="">1</span>
                            </div>
                            <div className="fancybox-toolbar">
                                <button data-fancybox-play="" className="fancybox-button fancybox-button--play" title="Start slideshow" style={{ display: "none" }}>
                                    <svg viewBox="0 0 40 40"><path d="M13,12 L27,20 L13,27 Z"></path>
                                        <path d="M15,10 v19 M23,10 v19"></path>
                                    </svg>
                                </button>

                                <button data-fancybox-fullscreen="" className="fancybox-button fancybox-button--fullscreen" title="Full screen">
                                    <svg viewBox="0 0 40 40">
                                        <path d="M9,12 h22 v16 h-22 v-16 v16 h22 v-16 Z"></path>
                                    </svg>
                                </button>
                                <button data-fancybox-thumbs="" className="fancybox-button fancybox-button--thumbs" title="Thumbnails" style={{ display: "none" }}>
                                    <svg viewBox="0 0 120 120">
                                        <path d="M30,30 h14 v14 h-14 Z M50,30 h14 v14 h-14 Z M70,30 h14 v14 h-14 Z M30,50 h14 v14 h-14 Z M50,50 h14 v14 h-14 Z M70,50 h14 v14 h-14 Z M30,70 h14 v14 h-14 Z M50,70 h14 v14 h-14 Z M70,70 h14 v14 h-14 Z"></path>
                                    </svg>
                                </button>
                                <button data-fancybox-share="" className="fancybox-button fancybox-button--share" title="Share">
                                    <svg viewBox="0 0 40 40">
                                        <path d="M6,30 C8,18 19,16 23,16 L23,16 L23,10 L33,20 L23,29 L23,24 C19,24 8,27 6,30 Z"></path>
                                    </svg>
                                </button>
                                <button data-fancybox-close="" onClick={() => setVideo(false)} className="fancybox-button fancybox-button--close" title="Close">
                                    <svg viewBox="0 0 40 40">
                                        <path d="M10,10 L30,30 M30,10 L10,30" />
                                    </svg>
                                </button>
                            </div>
                            <div className="fancybox-navigation">
                                <button data-fancybox-prev="" className="fancybox-button fancybox-button--arrow_left" title="Previous" >
                                    <svg viewBox="0 0 40 40">
                                        <path d="M10,20 L30,20 L10,20 L18,28 L10,20 L18,12 L10,20" />
                                    </svg>
                                </button>
                                <button data-fancybox-next="" className="fancybox-button fancybox-button--arrow_right" title="Next">
                                    <svg viewBox="0 0 40 40" >
                                        <path d="M30,20 L10,20 L30,20 L22,28 L30,20 L22,12 L30,20" />
                                    </svg>
                                </button>
                            </div>
                            <div className="fancybox-stage">
                                <div className="fancybox-slide fancybox-slide--iframe fancybox-slide--video fancybox-slide--current fancybox-slide--complete">
                                    <div className="fancybox-content">
                                        <Iframe id="fancybox-frame1671797716315" name="fancybox-frame1671797716315" className="fancybox-iframe" frameborder="0" vspace="0" hspace="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" allowtransparency="true" src={aboutimgtwo} scrolling="no"></Iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="fancybox-caption-wrap">
                                <div className="fancybox-caption"></div>
                            </div>
                        </div>
                    </div>
                }
            </section>
        </>
    )
}

export default Aboutsec