import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Iframe from 'react-iframe'
import bg25 from '../../assets/images/background/bg-25.png'
import bg6 from '../../assets/images/background/bg-6.png'
import restro from '../../assets/images/resource/contact-us.jpeg'

function Contactbanner() {

    const [isLoading, setIsLoading] = useState(false); // Ajout de l'état isLoading


    const handleFormSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true); // Activer l'indicateur de chargement au début de la requête

        const formData = new FormData(event.target);

        const dataToSend = {
            name: formData.get('fieldname'),
            telephone: formData.get('fieldnumber'),
            message: formData.get('fieldmessage'),

        };
        console.log(dataToSend)
        // Envoi de la requête POST
        fetch('https://vps98196.serveur-vps.net/api/Contacts', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Gérer la réponse ici
                setIsLoading(false); // Désactiver l'indicateur de chargement une fois la réponse obtenue

                alert('Votre message a été envoyé avec succès ! Nous vous contacterons sous peu.')
                console.log('Réponse du serveur :', data);
            })
            .catch(error => {
                // Gérer les erreurs ici
                setIsLoading(false); // Désactiver l'indicateur de chargement une fois la réponse obtenue
                alert('Une erreur est surevenue ! Merci de réessayer dans quelques instants.')
                console.error('Erreur lors de l\'envoi de la requête :', error);
            });
    };



    return (
        <>
            <div className="contact-map">
                
                <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d360262.28609061084!2d10.04850893549916!3d3.9431481249491482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x108bc59f829b4cff%3A0xae166b0bbf72d7a1!2sLe%20BACCHUS%20PLUS%20Yaound%C3%A9!5e0!3m2!1sfr!2scm!4v1696200411506!5m2!1sfr!2scm" width="100%" height="500" style="border:0;" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></Iframe>
            </div>

            <section className="contact-page">
                <div className="left-bg"><img src={bg25} alt="" title="" /></div>
                <div className="right-bg"><img src={bg6} alt="" title="" /></div>

                <div className="location-center">
                    <div className="auto-container">
                        <div className="cinfo-box">
                            <div className="row clearfix">

                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                        <h4>Nos horaires</h4>
                                        <div className="text">
                                            <li>Lundi à Samedi : 12h00 - 23h00</li>
                                            <li>Dimanche : sous réservation uniquement</li>

                                        </div>
                                    </div>
                                </div>


                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner-box cp-seprator wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                        <h4>Nous contacter</h4>
                                        <div className="text">
                                            <li>Restaurant Le Bacchus Plus,<br /> Elig - Essono, derrière Mahima Yaoundé - Cameroun</li>
                                            <li><Link to="mailto: bbacchus237@gmail.com">bbacchus237@gmail.com</Link></li>
                                            <li><Link to="tel:+237698598802">(+237) 698-598-802 / 697-106-601</Link></li>
                                        </div>

                                    </div>
                                </div>


                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                        <h4>Quelques précisions</h4>
                                        <div className="text">
                                            <li>Pour plus de 07 personnes, appelez-nous pour réserver une table.</li>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="auto-container">
                    <div className="c-page-form-box">
                        <div className="row clearfix">

                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                                <div className="title-box centered">
                                    <h2>Exprimez-vous</h2>
                                    <div className="text desc">Vous avez une question ? Un besoin ? Alors écrivez-nous !</div>
                                </div>
                                <div className="default-form reservation-form">
                                    <form onSubmit={handleFormSubmit}>
                                        <div className="clearfix">
                                            <div className="form-group">
                                                <div className="field-inner">
                                                    <input type="text" name="fieldname" placeholder="Votre nom" required="Le nom est obligatoire" />
                                                </div>
                                            </div>
                                          
                                            <div className="form-group ">
                                                <div className="field-inner">
                                                    <input type="text" name="fieldnumber" placeholder="Votre numéro de téléphone" required="Le numéro est obligatoire" />
                                                </div>
                                            </div>

                                            <div className="form-group ">
                                                <div className="field-inner">
                                                    <textarea name="fieldmessage" placeholder="Votre message" required="Votre message est obligatoire"></textarea>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <button type="submit" className="theme-btn btn-style-one clearfix" disabled={isLoading}>
                                                    <span className="btn-wrap">
                                                        <span className="text-one">{isLoading ? 'Patientez...' : 'Envoyer mon message'}</span>
                                                        <span className="text-two">{isLoading ? 'Patientez...' : 'Envoyer mon message'}</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>


                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                                <img src={restro} alt="" />
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Contactbanner