import React from 'react'
import Aboutsec from '../Menabout/Aboutsec'
import Aboutservice from '../Menabout/Aboutservice'
import Aboutwhyus from '../Menabout/Aboutwhyus'
import Sliderabout from '../Menabout/Sliderabout'
import Testimonialsabout from '../Menabout/Testimonialsabout'
import Winningchef from '../Menabout/Winningchef'
import Bredcrumb from '../Bredcrumb/Main'
import bgimg from '../../assets/images/resource/Story1.JPG';


function Main() {
  return (
    <>
      <Bredcrumb subtitle="À Propos de Nous" title="En savoir plus sur le Bacchus Plus" Img={bgimg} />
      <Aboutsec />
      <Winningchef />
      <Aboutservice />
      {/* <Testimonialsabout /> */}
      <Aboutwhyus />
      <Sliderabout />
    </>
  )
}
export default Main