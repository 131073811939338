import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/resource/blogo.jpg';
import logonew from '../../assets/images/logo.jpeg';
import imgone from '../../assets/images/resource/menu-image-1.jpg';
import imgtwo from '../../assets/images/resource/menu-image-2.jpg';
import Imgthree from '../../assets/images/resource/menu-image-3.jpg';
import Imgfour from '../../assets/images/resource/menu-image-4.jpg';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

function Main() {

    const [active, setActive] = useState();
    const [show, setShow] = useState();
    const [menu, setMenu] = useState();
    const [page, setPage] = useState();
    const [drop, setDrop] = useState();
    const [lastscrool, setlastscrool] = useState(0);

    const [scrolled, setScrolled] = useState(false);
    const [Nav, setNav] = useState(true);

    const handleScroll = () => {

        const offset = window.scrollY;
        setlastscrool(offset);

        if (offset > 1000 && offset < lastscrool) {
            setNav(true);
            setScrolled(true);
        }

        else if (offset > 1000) {
            setNav(false)
        }

        else if (offset > 200) {
            setNav(true);
            setScrolled(true);
        }

        else {
            setScrolled(false);
            setNav(true);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    })

    const sectionRef = useRef(null);
    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    };

    return (
        <>
            {active &&
                <div className="menu-backdrop" style={{ opacity: "1", visibility: "visible" }}></div>
            }
            <section className={`hidden-bar ${active && "visible-sidebar"}`} >
                <div className="inner-box">
                    <div className="cross-icon hidden-bar-closer" onClick={() => setActive(false)} ><span className="far fa-close" onClick={() => setActive(false)} > </span></div>
                    <div className="logo-box"><Link to="/" title="Le Bacchus Plus - Restaurants Gastronomique"><img src={logo} alt="" title="Le Bacchus Plus - Restaurants Gastronomique" />
                    </Link></div>

                    <div className="side-menu">
                        <ul className="navigation clearfix">

                            <li><Link to="/" onClick={() => setActive(false)}>Acceuil</Link></li>
                            {/* <li><Link to="/" onClick={() => setActive(false)}>Evenements</Link></li> */}
                            <li><Link to="/about" onClick={() => setActive(false)}>Restaurant</Link></li>
                            <li><Link to="/reservation" onClick={() => setActive(false)}>reservation</Link></li>
                            {/* <li><Link to="" onClick={() => setActive(false)}>Réservation</Link></li> */}
                            <li><Link to="/commande" onClick={() => setActive(false)}>Commande</Link></li>
                            {/* <li><Link to="" onClick={() => setActive(false)}>Fidélité</Link></li> */}


                            <li><Link to="/contact" onClick={() => setActive(false)}> Contact </Link></li>
                        </ul>
                    </div>

                   
                    <ul className="info">
                        <li>Restaurant Le Bacchus Plus, <br />  Elig - Essono, derrière Mahima Yaoundé - Cameroun </li>
                        <li>Ouvert : 12:00 - 23:00</li>
                        <li><Link to="mailto:bbacchus237@gmail.com">bbacchus237@gmail.com</Link></li>
                    </ul>
                    <div className="separator"><span></span></div>
                    <div className="booking-info">
                        <div className="bk-title">Réserver une table par appel</div>
                        <div className="bk-no"><Link to="tel:(+237) 698-598-802 / 697-106-601">(+237) 698-598-802 / 697-106-601</Link></div>
                    </div>

                </div>
            </section>
            {Nav &&
                <header className={`main-header ${scrolled && "fixed-header"} header-down`}>
                    <div className="header-top">
                        <div className="auto-container">
                            <div className="inner clearfix">
                                <div className="top-left clearfix">
                                    <ul className="top-info clearfix">
                                        <li><i className="icon far fa-map-marker-alt"></i> Restaurant Le Bacchus Plus, Elig - Essono, derrière Mahima Yaoundé - Cameroun </li>
                                        <li><i className="icon far fa-clock"></i> Lundi - Samedi : 12:00 - 23h00</li>
                                    </ul>
                                </div>
                                <div className="top-right clearfix">
                                    <ul className="top-info clearfix">
                                        <li><Link to="tel:+237698598802"><i className="icon far fa-phone"></i> (+237) 698-598-802 / 697-106-601 </Link> </li>
                                        <li><Link to="mailto:bbacchus237@gmail.com"><i className="icon far fa-envelope"></i> bbacchus237@gmail.com</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="header-upper header-fixed" >
                        <div className="auto-container">
                            <div className="main-box d-flex justify-content-between align-items-center flex-wrap">

                                <div className="links-box clearfix">
                                    <div className="nav-toggler">
                                        <button className="hidden-bar-opener">
                                            <span className="hamburger" onClick={() => setActive(true)}>
                                                <span className="top-bun"></span>
                                                <span className="meat"></span>
                                                <span className="bottom-bun"></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>

                                <div className="logo-box">
                                    <div className="logo"><Link to="/" title="Le Bacchus Plus - Restaurants Gastronomique"><img src={logo} alt="" title="Le Bacchus Plus - Restaurants Gastronomique" /></Link></div>
                                </div>

                                <div className="nav-box clearfix">

                                    <div className="nav-outer clearfix">
                                        <nav className="main-menu">
                                            <ul className="navigation clearfix">
                                                <li className="current dropdown"><Link to="/">Acceuil</Link> </li>

                                                <li className="dropdown has-mega-menu"><Link to="/menu">Menus</Link>
                                                    <ul>
                                                        <li>
                                                            <div className="mega-menu">
                                                                <div className="menu-inner">
                                                                    <div className="auto-container">
                                                                        <div className="row clearfix">
                                                                            <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                                                                <div className="image"><Link to="/menuone"><img src={imgone} alt="" /></Link></div>
                                                                                <div className="title"><Link to="/menuone">Menu list 1</Link></div>
                                                                            </div>
                                                                            <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                                                                <div className="image"><Link to="/commande"><img src={imgtwo} alt="" /></Link></div>
                                                                                <div className="title"><Link to="commande">Menu list 2</Link></div>
                                                                            </div>
                                                                            <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                                                                <div className="image"><Link to="/menuthree"><img src={Imgthree} alt="" /></Link></div>
                                                                                <div className="title"><Link to="menuthree">Menu list 3</Link></div>
                                                                            </div>
                                                                            <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                                                                <div className="image"><Link to="/reservation"><img src={Imgfour} alt="" /></Link></div>
                                                                                <div className="title"><Link to="/reservation">Menu list 4</Link></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><Link to="/about">About Us</Link></li>
                                                <li><Link to="/ourchefs">Our chefs</Link></li>
                                                <li className="dropdown"><Link to="/">Pages</Link>
                                                    <ul>
                                                        <li> <Link to="#">Dropdown Menu 1</Link></li>
                                                        <li> <Link to="#">Dropdown Menu 2</Link></li>
                                                        <li> <Link to="#">Dropdown Menu 3</Link></li>
                                                        <li className="dropdown"><Link to="#">Dropdown Menu 4</Link>
                                                            <ul>
                                                                <li> <Link to="#">Dropdown Menu level 2</Link></li>
                                                                <li> <Link to="#">Dropdown Menu level 2</Link></li>
                                                                <li> <Link to="#">Dropdown Menu Level 3</Link></li>
                                                            </ul>
                                                        </li>
                                                        <li><Link to="#">Dropdown Lorem 5</Link></li>
                                                    </ul>
                                                </li>
                                                <li><Link to="#">Blog</Link></li>
                                                <li><Link to="/contact">Contact</Link></li>
                                            </ul>
                                        </nav>
                                    </div>

                                </div>

                                <div className="link link-btn" onClick={() => scrollToSection('reserve-section')}>
                                    <Link to="/reservation" className="theme-btn btn-style-one clearfix">
                                        <span className="btn-wrap">
                                            <span className="text-one">Réserver une table</span>
                                            <span className="text-two">Réserver une table</span>
                                        </span>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </header>
            }
        </>
    )
}

export default Main