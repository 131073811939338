import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import Slideone from '../../assets/images/main-slider/slider-1.jpg';
import Slidetwo from '../../assets/images/main-slider/slider-2.jpg';
import Slidethree from '../../assets/images/main-slider/slider-3.jpg';
import bookicon1 from '../../assets/images/resource/tableBookingIcon.png';
import 'swiper/swiper.min.css';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.min.css';


import styled from "styled-components";
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();



const VideoComponent = styled.video`
min-width:100%;
min-height: 100vh;
position: absolute;
object-fit : cover;
z-index:-1;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

SwiperCore.use([Navigation]);

function Banner() {
    const sectionRef = useRef(null);
    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    };
    return (
        <Swiper
            className="banner-section banner-section-coustm"
            loop={true}
            spaceBetween={50}
            slidesPerView={1}
            navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }} // Use "navigation" with lowercase, and place it outside the SwiperSlide elements
        >
            <div className="banner-container">
                <div className="banner-slider">
                    <div className="swiper-wrapper">
                        {[Slideone, Slidetwo, Slidethree].map((image, index) => (
                            <SwiperSlide key={index} className="swiper-slide slide-item img-fluid">
                                <VideoComponent controls autoPlay loop>
                                    <source src={require('../../assets/images/resource/Intro.mp4')} type="video/mp4" />Your browser does not support the video tag. I suggest you upgrade your browser.
                                </VideoComponent>
                                <Overlay/>
                                <div className="auto-container">
                                    <div className="content-box">
                                        <div className="content">
                                            <div className="clearfix">
                                                <div className="inner">
                                                    <div className="subtitle">
                                                        <span style={{fontSize:18}}>Le Bacchus Plus</span>
                                                    </div>

                                                    <h1><span>{index === 0 ? 'Votre restaurant' : index === 1 ? 'Un plat,' : 'Par amour pour'}<br />{index === 0 ? 'gastronomique.' : index === 1 ? 'une histoire.' : 'l\'art culinaire.'}</span></h1>
                                                    <div className="text">Venez et goûtez à la joie d'une cuisine alléchante.</div>
                                                    <div className="links-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                        <div className="link" onClick={() => scrollToSection('section-menu')}>
                                                            <Link to="#section-menu" className="theme-btn btn-style-two clearfix">
                                                                <span className="btn-wrap">
                                                                    <span className="text-one">Notre Carte</span>
                                                                    <span className="text-two">Notre Carte</span>
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </div>
                </div>
            </div>
            <div className="swiper-button-prev">
                <span className="fal fa-angle-left"></span>
            </div>
            <div className="swiper-button-next">
                <span className="fal fa-angle-right"></span>
            </div>
            <div className="book-btn" onClick={() => scrollToSection('reserve-section')}>
                <Link to="#reserve-section" className="theme-btn">
                    <span className="icon">
                        <img src={bookicon1} alt="" title="" />
                    </span>
                    <span className="txt">Réserver une table</span>
                </Link>
            </div>
        </Swiper>
    );
}

export default Banner;
