import React from 'react'
import twoBg from '../../assets/images/background/approache3.jpg';
import threebg from '../../assets/images/background/ourspace.JPG';
import { Link } from 'react-router-dom'
import bgfive from '../../assets/images/background/image-5.jpg'
import bg12 from '../../assets/images/background/service-image.png'
import signaturetwo from '../../assets/images/resource/signature-2.png'
import bg13 from '../../assets/images/background/bg-1.png'

function Winningchef() {
    return (
        <>
            <section className="fluid-section">
                <div className="outer-container">
                    <div className="row clearfix">
                        <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image-layer" style={{ backgroundImage: `url(${twoBg})` }}></div>

                             
                            </div>
                        </div>

                        <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="right-bg"><img src={bg12} alt="" title="" /></div>
                            <div className="inner clearfix wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="content-box">
                                    <div className="title-box centered">
                                        <div className="subtitle"><span>Notre cuisine, davantage détaillée</span></div>
                                        <div className="pattern-image">
                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                        </div>
                                        <h2>Nos Approches Culinaires</h2>
                                        <ol>
                                            <li>
                                                <div className="text">
                                                    <strong>Pour commencer...</strong><br />


                                                    L'apéritif propose notre fameux beurre au miel et à la moutarde maison. </div>
                                            </li>
                                            <li>
                                                <div className="text" >
                                                    <strong>Ensuite ...</strong><br />

                                                    Choisissez entre viandes, poissons, fruits de mer ou pâtes. Nos plats sont délicieusement assaisonnés avec des épices variées, accompagnés de légumes.
                                                </div>
                                            </li>
                                            <li>
                                                <div className="text" >
                                                    <strong>Pour conclure ...</strong><br />

                                                    Découvrez notre relecture contemporaine du fruit du mois, en plus de nos classiques comme le tiramisu et la mousse au chocolat.  </div>
                                            </li>

                                        </ol>


                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="fluid-section alternate">
                <div className="outer-container">
                    <div className="row clearfix">

                        <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image-layer" style={{ backgroundImage: `url(${threebg})` }}></div>
                                <div className="image"><img src={threebg} alt="" /></div>
                            </div>
                        </div>

                        <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="left-bg"><img src={bg13} alt="" title="" /></div>
                            <div className="inner clearfix wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="content-box">
                                    <div className="title-box centered">
                                        <div className="subtitle"><span>La diversité, c'est aussi notre fort</span></div>

                                        <div className="pattern-image">
                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                        </div>

                                        <h2>Nos espaces</h2>
                                        <div className="text">Notre restaurant offre une expérience polyvalente en proposant des espaces intérieurs et extérieurs adaptés à tous les événements de nos clients. Nous nous engageons à personnaliser chaque espace selon chacun de vos besoins, créant ainsi des souvenirs inoubliables à chaque événement.</div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Winningchef