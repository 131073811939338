import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Backgroundimage from "../../../assets/images/background/image-8.jpg";
import bg19 from "../../../assets/images/background/bg-19.png";
import bg20 from "../../../assets/images/background/bg-20.png";
import img8 from "../../../assets/images/background/image-8.jpg";
import badgeone from "../../../assets/images/resource/badge-2.png";
import One from '../../../assets/images/resource/menu-image-11.jpg'
import Two from '../../../assets/images/resource/menu-image-12.jpg'
import Three from '../../../assets/images/resource/menu-image-13.jpg'
import Four from '../../../assets/images/resource/menu-image-14.jpg'
import Five from '../../../assets/images/resource/menu-image-15.jpg'
import Six from '../../../assets/images/resource/menu-image-16.jpg'
import Seven from '../../../assets/images/resource/menu-image-17.jpg'
import Eight from '../../../assets/images/resource/menu-image-18.jpg'
import axios from "axios";
import Zoom from 'react-reveal/Zoom';
import './Panier.css';
import styled from "styled-components";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from "formik";
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const CarteContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  gap: 20px;
  padding: 60px;
  position: relative; /* Assurez-vous que le contenu de CarteContainer est empilé au-dessus de l'overlay */
  z-index: 1; /* Gardez le contenu au-dessus de l'overlay */
`;


const Modalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 30,
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 2,

    boxShadow: 24,
    p: 4,
};

const Container = styled.div`

  border: 2px solid rgb(228,197,144);
margin-top:10px;
border-radius:25px;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const Myh2 = styled.h2` {
    text-align: center;
    margin-bottom: 20px;
    z-index:30;
    color: black;
   
  
  }
  `

const MyButton = styled.a`
  background-color: transparent;
  border: 2px solid rgb(228,197,144);
  color: white;
  padding: 10px 20px;
  align-self:center;

  text-decoration: none;
 
  transition: background-color 0.3s, color 0.3s;
border-radius:8px;
  &:hover {
    background-color: white;
    color: black
  }
`;

const MyBadge = styled.span` {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 12px;
  position: absolute;
  top: -8px;
  right: -8px;
}
`;
const StyledButton = styled.button`
  background-color: rgb(228,197,144);
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
height:60px;
  &:hover {
    background-color: transparent;
    border: 2px solid rgb(228,197,144);
    color:white;
  }
`;

const QuantityButton = styled.button`

    background-color: rgb(228,197,144);

    border: none;
    padding: 1px 10px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 50%;
    margin: 5px 5px;
    outline: none; // Supprime la bordure par défaut
 

`




function Specialmenuconfirm() {

    const navigate = useNavigate();
    const [cartItemCount, setCartItemCount] = useState(0);
    const [plats, setPlats] = useState([]);

    const location = useLocation();
    const cartItems = location.state ? Object.values(location.state.cart) : [];
    const [cart, setCart] = useState(location.state.cart);


    const increaseQuantity = (platId) => {
        const updatedCart = { ...cart };
        updatedCart[platId].quantity += 1;
        setCart(updatedCart);
        setCartItemCount(cartItemCount + 1);
    };

    const decreaseQuantity = (platId) => {
        const updatedCart = { ...cart };
      
        if (updatedCart[platId].quantity > 1) {
            updatedCart[platId].quantity -= 1;
        } else {
            updatedCart[platId].quantity = 0;

            delete updatedCart[platId]; // Supprimer l'article lorsque la quantité atteint 0
        }
        setCart(updatedCart);
        console.log(cart)

        setCartItemCount(cartItemCount - 1); // Décrémenter le compteur du panier
    
        // Si le panier est vide, redirigez l'utilisateur ou effectuez une autre action
        if (Object.keys(updatedCart).length === 0) {
            // Exemple : Redirection vers une autre page
            navigate('/commande');
        }
    };



    const totalAmount = Object.values(location.state.cart).reduce((total, plat) => {
        return total + plat.prix * plat.quantity ;
    }, 0);

    const initialValues = {
        name: '',
        phoneNumber: '',
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Le nom est requis'),
        phoneNumber: Yup.string()
            .matches(/^\d{9}$/, 'Le numéro de téléphone doit comporter 9 chiffres')
            .required('Le numéro de téléphone est requis'),
    });
    const [showModal, setShowModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Ajout de l'état isLoading

    const handleClose = () => {
        setShowModal(false);
        navigate(-1);
    }
    const handleCloseErrorModal = () => setShowErrorModal(false);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setIsLoading(true); // Activer l'indicateur de chargement au début de la requête
                const response = await axios.post(
                    'https://vps98196.serveur-vps.net/api/Commandes/commander',
                    {
                        clientName: values.name,
                        clientPhoneNumber: values.phoneNumber,
                        articles: cartItems,
                    }
                );

                console.log('Réponse du serveur:', response.data);



                // Réinitialisez le formulaire après la soumission réussie
                formik.resetForm();
                setIsLoading(false); // Désactiver l'indicateur de chargement une fois la réponse obtenue

                // setModalContent(response.data);
                // setShowModal(true);
                alert('La commande a été effectué avec succès ! Nous vous contacterons sous peu pour davantage d\'informations')
                console.log('Réponse du serveur :', response.data);
                navigate(-1);

            } catch (error) {
                if (error.message.match(/network/i)) {
                    alert('Il semblerait qu\'il y ait un souci avec votre connexion internet. Merci de reessayer plus tad.');
                } else {
                    alert('Une erreur est surevenue ! Merci de réessayer dans quelques instants.');

                }
                console.error('Erreur lors de la requête POST :', error);
                setIsLoading(false); // Désactiver l'indicateur de chargement en cas d'erreur
            }
        },
    });

    return (
        <>

            <section className="special-offer-two">
                <div className="left-bg">
                    <img src={bg20} alt="" title="" />
                </div>
                <div className="right-bg">
                    <img src={bg19} alt="" title="" />
                </div>
                <div className="auto-container">
                    <div className="title-box centered">

                        <Container>
                            {Object.values(location.state.cart).length === 0 ?
                                <h1 style={{ color: 'white' }}>Votre panier est vide ! </h1>
                                :
                                <>
                                    <div className="panier-container">
                                        {Object.values(cart).map((plat) => (
                                        
                                            <div>
                                                 
                                                    
                                                    <div  className="plat-item" key={plat.id}>
                                                    {/* <img
                                                        src={plat.image} // Utilisez la source de l'image du plat
                                                        alt={plat.nom}
                                                        className="plat-image" // Appliquez la classe pour les images de plat
                                                    /> */}
    
                                                    <div style={{ width: 100 }}>
                                                        <h3>Plat</h3>
                                                        <p>{plat.nom}</p>
                                                    </div>
                                                    <div>
                                                        <h3>Quantité</h3>
                                                         <div>
                                                        
                                                        <QuantityButton onClick={() => decreaseQuantity(plat.id)}>-</QuantityButton>
                                                        <span>{plat.quantity}</span>
                                                        <QuantityButton onClick={() => increaseQuantity(plat.id)}>+</QuantityButton>
                                                    </div>
                                                        
                                                    </div>
    
                                                    <div>
                                                        <h3>Prix unitaire</h3>
                                                        <p>{plat.prix} XAF</p>
                                                    </div>
                                                    <div>
                                                        <h3>Sous-total</h3>
                                                        <p>{(plat.prix * plat.quantity).toFixed(0)} XAF</p>
    
                                                    </div>
    
    
                                                </div>
                                                 
                                            </div>
                                            
                                        ))}


                                        <br />

                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="form-group">
                                                <Myh2>Prix total : {totalAmount.toFixed(0)} XAF</Myh2>
                                                <input
                                                    className="panier-input"
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    placeholder='Votre nom'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name} />
                                                {formik.touched.name && formik.errors.name ? (
                                                    <div className="error-message">{formik.errors.name}</div>
                                                ) : null}
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    className="panier-input"
                                                    type="text"
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    placeholder='Votre numéro de téléphone'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.phoneNumber} />
                                                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                                    <div className="error-message">{formik.errors.phoneNumber}</div>
                                                ) : null}
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                                <StyledButton
                                                    disabled={isLoading ? true : false}
                                                    style={{ alignSelf: 'center', opacity: isLoading ? 0.3 : 1 }} type="submit">
                                                    {isLoading ? 'Patientez...' : 'Commander maintenant'}
                                                </StyledButton>
                                            </div>
                                            <br />
                                        </form>
                                    </div>
                                    <Modal
                                        open={showModal}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={Modalstyle}>
                                            <Typography id="modal-modal-title" variant="h6" component="Myh2">
                                                Commande effectuée avec succès !
                                            </Typography>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                Votre commande a été bien prise en compte ! Nous vous contacterons sous peu pour une confirmation.
                                            </Typography>
                                            <StyledButton style={{ alignSelf: 'center' }} onClick={handleClose}>
                                                D'accord
                                            </StyledButton>
                                        </Box>
                                    </Modal>

                                    <Modal
                                        open={showErrorModal}
                                        onClose={handleCloseErrorModal}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={Modalstyle}>
                                            <Typography id="modal-modal-title" variant="h6" component="Myh2">
                                                Une erreur est survenue
                                            </Typography>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                Merci de vérifier votre connexion internet
                                            </Typography>
                                            <StyledButton style={{ alignSelf: 'center' }} onClick={handleCloseErrorModal}>
                                                D'accord
                                            </StyledButton>
                                        </Box>
                                    </Modal>


                                </>
                            }
                        </Container>






                    </div>

                </div>
            </section>

        </>
    );
}

export default Specialmenuconfirm;
