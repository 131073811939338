import React from 'react';
import bg8 from '../../assets/images/background/bg-8.png';
import bg7 from '../../assets/images/background/bg-7.png';
import whyiconone from '../../assets/images/resource/hygienicFood.png';
import whyicontwo from '../../assets/images/resource/freshEnvironment.png';
import whyiconthree from '../../assets/images/resource/skilledChief.png';
import whyiconfour from '../../assets/images/resource/eventAndParty.png';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
function Whyus() {
  const whyData = [
    {
      icon: whyiconone,
      title: 'Hygiène & qualité',
      text: 'Chez-nous, mangez sain et équilibré, dans les meilleures conditions.',
    },
    {
      icon: whyicontwo,
      title: 'Environnement ouvert',
      text: 'Profitez de la quiétude et du calme de notre espace.',
    },
    {
      icon: whyiconthree,
      title: 'Chef talentueux',
      text: 'Succombez aux délices de notre chef.',
    },
    {
      icon: whyiconfour,
      title: 'Événements',
      text: 'Vous avez la possibilité d\'organiser des évènements chez-nous.',
    },
  ];

  return (
    <>
      <section className="why-us">
        <Fade left>
          <div className="left-bg">
            <img src={bg8} alt="" title="" />
          </div>
        </Fade>
    
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>Toujours sceptique ?</span>
            </div>
            <div className="pattern-image">
              <img
                src={require('../../assets/images/icons/separator.svg').default}
                alt="mySvgImage"
              />
            </div>

            <h2>Nos plus</h2>
          </div>
          <div className="row clearfix">
            {whyData.map((data, index) => (
              <Fade bottom>
                <div key={index} className="why-block col-xl-3 col-lg-6 col-md-6 col-sm-12"
                >
                  <div
                    className="inner-box wow fadeInUp"
                    data-wow-duration="1500ms"
                    data-wow-delay={`${index * 300}ms`}
                  >
                    <div className="icon-box">
                      <img src={data.icon} alt="" />
                    </div>
                    <h4>{data.title}</h4>
                    <div className="text">{data.text}</div>
                  </div>
                </div>
              </Fade>

            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Whyus;
