import React from 'react'
import { Link } from 'react-router-dom'
import bg3 from '../../assets/images/background/bg-3.png'
import badge1 from '../../assets/images/resource/badge-1.png'
import img1 from '../../assets/images/resource/image-1.jpg'
import img2 from '../../assets/images/resource/image-2.jpg'
import styled from 'styled-components'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';


const VideoComponent = styled.video`
min-width:100%;
min-height: 100vh;
position: absolute;
object-fit : cover;
top:0;
`;

const Overlay = styled.div`
  position: absolute;

  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
`;


function Story() {
    return (
        <>
            <section className="story-section">
                <Fade left>
                    <div className="left-bg">
                        <img src={bg3} alt="" title="" />
                    </div>
                </Fade>
             <Fade bottom>
             <div className="auto-container">
                    <div className="row clearfix">
                        <div className="text-col col-xl-5 col-lg-5 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="title-box centered">
                                    <div className="subtitle">
                                        <span></span>
                                    </div>


                                    <h2>Passez vos commandes !</h2>
                                    <div className="text">Parcourez notre carte, choisissez parmi nos délices et passez vos commandes en quelques clics. Vivez l'expérience 'Le Bacchus Plus' autrement grâce à notre service de commande en ligne.</div>
                                </div>
                                <div className="booking-info">
                                    <div className="bk-title">Commander en ligne ou par appel au</div>
                                    <div className="bk-no"><Link to="tel:+237698598802"> (+237) 698 598 802 / 697 106 601</Link></div>
                                    <div className="link-box">
                                        <Link to="/commande" className="theme-btn btn-style-two clearfix">
                                            <span className="btn-wrap">
                                                <span className="text-one">Commander maintenant</span>
                                                <span className="text-two">Commander maintenant</span>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Fade right>
                            <div className="image-col col-xl-7 col-lg-7 col-md-12 col-sm-12">
                                <VideoComponent controls autoPlay loop>
                                    <source src={require('../../assets/images/resource/OrderNow.mp4')} type="video/mp4" />Your browser does not support the video tag. I suggest you upgrade your browser.
                                </VideoComponent>
                                <Overlay />
                            </div>
                        </Fade>
                    </div>
                </div>
             </Fade>
            </section>
        </>
    )
}

export default Story